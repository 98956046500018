import React, {useState , useEffect} from 'react';
import './template01.css';
import getData from './data.js';
import { BrowserRouter as Router, useLocation  } from 'react-router-dom';
import { MARKET_SUBVERSION_MAP } from '../MarketSubVersionMap';

function Template01() {
  const portrait = window.matchMedia("(orientation: portrait)").matches;
  let searchParameter = useLocation().search;
  let str = useLocation().pathname.split("/");
  const version = {
    number: str[2],
    market: str[3] || "nyc",
    subVersionFromLink:  getSubVersionFromLink(str),
    language: getLanguageFromLink(str)
  };

  const { number, market, subVersionFromLink, language } = version;
  let event = searchParameter.includes("onDemand") ? "onDemand" : "onLogin";

  function getSubVersionFromLink(str) {
    const marketVersion = str[2];

    if(str.length === 4){
      if(portrait && marketVersion === '01')
        return '02';
      else if(portrait && marketVersion === '04')
        return '01';
      else if(portrait && marketVersion === '06')
        return '01';
      else
      return 0;
    } else if(str.length === 5){
      return Number.parseInt(str[str.length-1]) ? str[str.length-1] : 0
    } else if(str.length === 6){
      return str[4]
    }
    return 0;
  }

  function getLanguageFromLink(str) {
    if(str.length === 4){
      return "english";
    } else if(str.length === 5){
      return !Number.parseInt(str[4]) ? str[4] : "english"
    } else if(str.length === 6){
      return str[5]
    }
    return "english";
  }

  const subVersion = subVersionFromLink || MARKET_SUBVERSION_MAP[`${market}${number}`][Number.parseInt(subVersionFromLink)];
  const [step, setStep] = useState(1);
  const [isEnabled, setEnabled] = useState(false);
  const [isFinalStepReached, setIsFinalStepReached] = useState(false);
  const [data, setData] = useState(getData({number,market, subVersion, language, event }));
  const screenData = data.screens;
  const [screen, setScreen] = useState(screenData[0]);
  const [isHidden, setIsHidden] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const onlyOneLanguage = (number.includes('01') && subVersion.includes('02') && portrait) ||
                                    number.includes('03') ||
                                    number.includes('04') ||
                                    number.includes('05') ||
                                    number.includes('06') ||
                                    number.includes('07') ||
                                    number.includes('09') ||
                                    number.includes('10') ||
                                    number.includes('11') ||
                                    number.includes('12') ||
                                    number.includes('13') ||
                                    number.includes('14') ||
                                    number.includes('15');

  const isPortraitView = (number.includes('01') && subVersion.includes('02')) ||
                          (number.includes('04') && subVersion.includes('01')) ||
                          (number.includes('06') && subVersion.includes('01'));

  useEffect(()=>{
    setEnabled(false);
    if(!isFinalStepReached && step === screenData.length) setIsFinalStepReached(true);

    if(isFinalStepReached){
      setEnabled(true);
      loadButtons();

    } else{

      //set time delay
      loadButtons();
      const timer = setTimeout(() => {
        setEnabled(true);
      }, 3000);

      return () => {
        clearTimeout(timer);
      }
    }

  }, [loaded]);

  useEffect(() => {
    window.mixpanel.track(data.mixpanelPageview);
  }, []);

  useEffect(() => {
    let index = screenData.findIndex((element) => element === screen);
    setStep(index + 1);
  }, [screen])

  const loadButtons = () => {
    let timer1;
    if(loaded) {
      timer1 = setTimeout(() => {
        setIsHidden(false);
      }, 500);
    }

    return () => {
      if(timer1) clearTimeout(timer1);
    }
  }

  const goToNextStep = () => {
    window.mixpanel.track(data.mixpanelNextClick);
    setIsHidden(true);
    setLoaded(false);
    setScreen(screenData[step]);
  }

  const goToBackStep = () => {
    window.mixpanel.track(data.mixpanelBackClick);
    setIsHidden(true);
    setLoaded(false);
    setScreen(screenData[step-2]);
  }

  const handleClickDone = () => {
    window.mixpanel.track(data.mixpanelDoneClick);
    //if onDemand then take to index page
    if(searchParameter.includes("onDemand")){
      let host = window.location.origin;
      let searchParam = window.location.search;
      window.location.assign(`${host}/training/index/${market}${searchParam}`);
    } else {
      //else if onLogin take them out of training
      window.dispatchEvent(new Event('messageMobileShouldContinue'));
    }
  }

  const getLanguageUrl = (language) => {
    let subVersionNo = subVersionFromLink === 0 ? "" : "/"+subVersionFromLink;
    return `/training/${number}/${market}${subVersionNo}/${language}`;
  }

  const goToLanguage1 = () => {
    let host = window.location.origin;
    let newLocation = getLanguageUrl(data.languageButton1Translate);
    let searchParam = window.location.search;
    window.location.assign(host + newLocation + searchParam);
  }

  const goToLanguage2 = () => {
    let host = window.location.origin;
    let newLocation = getLanguageUrl(data.languageButton2Translate);
    let searchParam = window.location.search;
    window.location.assign(host + newLocation + searchParam);
  }

  const goToRestart = () => {
    window.mixpanel.track(data.mixpanelRestartClick);

    let host = window.location.origin;
    let searchParam = window.location.search;
    let subVersionNo = subVersionFromLink === 0 ? "" : "/"+subVersionFromLink;
    window.location.assign(host+`/training/${number}/${market}${subVersionNo}`+searchParam);
  }

  return (
    <div className="driver-training-page">
      <div className={`training-info-graphic ${isPortraitView ? "portrait-view" : ""}`}>
        <img src={screen} className="info-graphic-screen" alt="training-screen" onLoad={() => setLoaded(true)}/>
      </div>
      <div className="button-container" hidden={isHidden}>
        {step === 1 &&
        <div className={`language-button-container 
                              ${number.includes("02") ? "language-button-container-02" : ""}
                              ${onlyOneLanguage ? "button-hidden" : ""}`}>
          <button className="driver-training-button language-button" onClick={goToLanguage1}>{data.languageButton1}</button>
          <button className="driver-training-button language-button" onClick={goToLanguage2}>{data.languageButton2}</button>
        </div>
        }
        {step > 1 &&
        <div className="language-button-container">
          <button className="driver-training-button restart-button" onClick={goToRestart}>{data.restart}</button>
        </div>
        }
        {step > 1 &&
        <button className="driver-training-button back-button" onClick={goToBackStep}>{data.back}</button>
        }
        {step < screenData.length &&
        <button className="driver-training-button" disabled={!isEnabled} onClick={goToNextStep}>{data.next}</button>
        }
        {step === screenData.length &&
        <button className="driver-training-button" disabled={!isEnabled} onClick={handleClickDone}>{data.done}</button>
        }
      </div>
    </div>
  );
}

export default Template01;
