import React from 'react';
import './driverTraining.css';
import Template01 from './templates/template01.js';
import { BrowserRouter as Router, Route, Routes, useRoutes } from 'react-router-dom';

export const DriverTraining = () => {
  const element = <Template01 />;

  const trainingVersions =
    [
      '/training/01/nyc','/training/01/nyc/english','/training/01/nyc/bengali','/training/01/nyc/spanish',
      '/training/01/nyc/01','/training/01/nyc/01/english','/training/01/nyc/01/bengali','/training/01/nyc/01/spanish',
      '/training/01/chicago','/training/01/chicago/english','/training/01/chicago/spanish','/training/01/chicago/bengali',
      '/training/01/chi','/training/01/chi/english','/training/01/chi/spanish','/training/01/chi/bengali',
      '/training/01/phl','/training/01/phl/english','/training/01/phl/spanish','/training/01/phl/bengali',
      '/training/01/dc', '/training/01/dc/english','/training/01/dc/bengali','/training/01/dc/spanish',
      '/training/01/la','/training/01/la/english','/training/01/la/spanish','/training/01/la/bengali',
      '/training/01/bos','/training/01/bos/english','/training/01/bos/spanish','/training/01/bos/bengali',
      '/training/01/miami','/training/01/miami/english','/training/01/miami/spanish','/training/01/miami/bengali',
      '/training/01/fort_lau','/training/01/fort_lau/english','/training/01/fort_lau/spanish','/training/01/fort_lau/bengali',
      '/training/01/nola','/training/01/nola/english','/training/01/nola/spanish','/training/01/nola/bengali',
      '/training/01/reno','/training/01/reno/english','/training/01/reno/spanish','/training/01/reno/bengali',
      '/training/01/sanfran','/training/01/sanfran/english','/training/01/sanfran/spanish','/training/01/sanfran/bengali',
      '/training/01/wpb','/training/01/wpb/english','/training/01/wpb/spanish','/training/01/wpb/bengali',
      '/training/01/nyc/02','/training/01/nyc/02/english',
      '/training/01/chicago/02','/training/01/chicago/02/english',
      '/training/01/chi/02','/training/01/chi/02/english',
      '/training/01/phl/02','/training/01/phl/02/english',
      '/training/01/dc/02', '/training/01/dc/02/english',
      '/training/01/la/02','/training/01/la/02/english',
      '/training/01/bos/02','/training/01/bos/02/english',
      '/training/01/miami/02','/training/01/miami/02/english',
      '/training/01/fort_lau/02','/training/01/fort_lau/02/english',
      '/training/01/nola/02','/training/01/nola/02/english',
      '/training/01/reno/02','/training/01/reno/02/english',
      '/training/01/sanfran/02','/training/01/sanfran/02/english',
      '/training/01/wpb/02','/training/01/wpb/02/english',
      '/training/02/nyc','/training/02/nyc/english','/training/02/nyc/bengali','/training/02/nyc/spanish',
      '/training/02/nyc/01','/training/02/nyc/01/english',
      '/training/02/chicago','/training/02/chicago/english','/training/02/chicago/bengali','/training/02/chicago/spanish',
      '/training/02/chi','/training/02/chi/english','/training/02/chi/bengali','/training/02/chi/spanish',
      '/training/02/phl','/training/02/phl/english','/training/02/phl/spanish','/training/02/phl/bengali',
      '/training/02/dc', '/training/02/dc/english','/training/02/dc/bengali','/training/02/dc/spanish',
      '/training/02/la','/training/02/la/english','/training/02/la/spanish','/training/02/la/bengali',
      '/training/02/bos','/training/02/bos/english','/training/02/bos/spanish','/training/02/bos/bengali',
      '/training/02/miami','/training/02/miami/english','/training/02/miami/spanish','/training/02/miami/bengali',
      '/training/02/fort_lau','/training/02/fort_lau/english','/training/02/fort_lau/spanish','/training/02/fort_lau/bengali',
      '/training/02/nola','/training/02/nola/english','/training/02/nola/spanish','/training/02/nola/bengali',
      '/training/02/reno','/training/02/reno/english','/training/02/reno/spanish','/training/02/reno/bengali',
      '/training/02/sanfran','/training/02/sanfran/english','/training/02/sanfran/spanish','/training/02/sanfran/bengali',
      '/training/02/wpb','/training/02/wpb/english','/training/02/wpb/spanish','/training/02/wpb/bengali',
      '/training/03/nyc','/training/03/nyc/english',
      '/training/03/chicago','/training/03/chicago/english',
      '/training/03/chi','/training/03/chi/english',
      '/training/03/phl','/training/03/phl/english',
      '/training/03/dc', '/training/03/dc/english',
      '/training/03/la','/training/03/la/english',
      '/training/03/bos','/training/03/bos/english',
      '/training/03/miami','/training/03/miami/english',
      '/training/03/fort_lau','/training/03/fort_lau/english',
      '/training/03/nola','/training/03/nola/english',
      '/training/03/reno','/training/03/reno/english',
      '/training/03/sanfran','/training/03/sanfran/english',
      '/training/03/wpb','/training/03/wpb/english',
      '/training/04/nyc','/training/04/nyc/english',
      '/training/04/chicago','/training/04/chicago/english',
      '/training/04/chi','/training/04/chi/english',
      '/training/04/phl','/training/04/phl/english',
      '/training/04/dc', '/training/04/dc/english',
      '/training/04/la','/training/04/la/english',
      '/training/04/bos','/training/04/bos/english',
      '/training/04/miami','/training/04/miami/english',
      '/training/04/fort_lau','/training/04/fort_lau/english',
      '/training/04/nola','/training/04/nola/english',
      '/training/04/reno','/training/04/reno/english',
      '/training/04/sanfran','/training/04/sanfran/english',
      '/training/04/wpb','/training/04/wpb/english',
      '/training/04/nyc/01','/training/04/nyc/01/english',
      '/training/04/chicago/01','/training/04/chicago/01/english',
      '/training/04/chi/01','/training/04/chi/01/english',
      '/training/04/phl/01','/training/04/phl/01/english',
      '/training/04/dc/01', '/training/04/dc/01/english',
      '/training/04/la/01','/training/04/la/01/english',
      '/training/04/bos/01','/training/04/bos/01/english',
      '/training/04/miami/01','/training/04/miami/01/english',
      '/training/04/fort_lau/01','/training/04/fort_lau/01/english',
      '/training/04/nola/01','/training/04/nola/01/english',
      '/training/04/reno/01','/training/04/reno/01/english',
      '/training/04/sanfran/01','/training/04/sanfran/01/english',
      '/training/04/wpb/01','/training/04/wpb/01/english',
      '/training/05/nyc','/training/05/nyc/english',
      '/training/05/chicago','/training/05/chicago/english',
      '/training/05/chi','/training/05/chi/english',
      '/training/05/phl','/training/05/phl/english',
      '/training/05/dc', '/training/05/dc/english',
      '/training/05/la','/training/05/la/english',
      '/training/05/bos','/training/05/bos/english',
      '/training/05/miami','/training/05/miami/english',
      '/training/05/fort_lau','/training/05/fort_lau/english',
      '/training/05/nola','/training/05/nola/english',
      '/training/05/reno','/training/05/reno/english',
      '/training/05/sanfran','/training/05/sanfran/english',
      '/training/05/wpb','/training/05/wpb/english',
      '/training/06/nyc','/training/06/nyc/english',
      '/training/06/chicago','/training/06/chicago/english',
      '/training/06/chi','/training/06/chi/english',
      '/training/06/phl','/training/06/phl/english',
      '/training/06/dc', '/training/06/dc/english',
      '/training/06/la','/training/06/la/english',
      '/training/06/bos','/training/06/bos/english',
      '/training/06/miami','/training/06/miami/english',
      '/training/06/fort_lau','/training/06/fort_lau/english',
      '/training/06/nola','/training/06/nola/english',
      '/training/06/reno','/training/06/reno/english',
      '/training/06/sanfran','/training/06/sanfran/english',
      '/training/06/wpb','/training/06/wpb/english',
      '/training/06/nyc/01','/training/06/nyc/01/english',
      '/training/06/chicago/01','/training/06/chicago/01/english',
      '/training/06/chi/01','/training/06/chi/01/english',
      '/training/06/phl/01','/training/06/phl/01/english',
      '/training/06/dc/01', '/training/06/dc/01/english',
      '/training/06/la/01','/training/06/la/01/english',
      '/training/06/bos/01','/training/06/bos/01/english',
      '/training/06/miami/01','/training/06/miami/01/english',
      '/training/06/fort_lau/01','/training/06/fort_lau/01/english',
      '/training/06/nola/01','/training/06/nola/01/english',
      '/training/06/reno/01','/training/06/reno/01/english',
      '/training/06/sanfran/01','/training/06/sanfran/01/english',
      '/training/06/wpb/01','/training/06/wpb/01/english',
      '/training/07/nyc','/training/07/nyc/english',
      '/training/07/chicago','/training/07/chicago/english',
      '/training/07/chi','/training/07/chi/english',
      '/training/08/nyc','/training/08/nyc/english','/training/08/nyc/bengali','/training/08/nyc/spanish',
      '/training/08/chicago','/training/08/chicago/english','/training/08/chicago/spanish','/training/08/chicago/bengali',
      '/training/08/chi','/training/08/chi/english','/training/08/chi/spanish','/training/08/chi/bengali',
      '/training/08/phl','/training/08/phl/english','/training/08/phl/spanish','/training/08/phl/bengali',
      '/training/08/dc', '/training/08/dc/english','/training/08/dc/bengali','/training/08/dc/spanish',
      '/training/08/la','/training/08/la/english','/training/08/la/spanish','/training/08/la/bengali',
      '/training/08/bos','/training/08/bos/english','/training/08/bos/spanish','/training/08/bos/bengali',
      '/training/08/miami','/training/08/miami/english','/training/08/miami/spanish','/training/08/miami/bengali',
      '/training/08/fort_lau','/training/08/fort_lau/english','/training/08/fort_lau/spanish','/training/08/fort_lau/bengali',
      '/training/08/nola','/training/08/nola/english','/training/08/nola/spanish','/training/08/nola/bengali',
      '/training/08/reno','/training/08/reno/english','/training/08/reno/spanish','/training/08/reno/bengali',
      '/training/08/sanfran','/training/08/sanfran/english','/training/08/sanfran/spanish','/training/08/sanfran/bengali',
      '/training/08/wpb','/training/08/wpb/english','/training/08/wpb/spanish','/training/08/wpb/bengali',
      '/training/09/nyc','/training/09/nyc/english',
      '/training/09/chicago','/training/09/chicago/english',
      '/training/09/chi','/training/09/chi/english',
      '/training/09/phl','/training/09/phl/english',
      '/training/09/dc', '/training/09/dc/english',
      '/training/09/la','/training/09/la/english',
      '/training/09/bos','/training/09/bos/english',
      '/training/09/miami','/training/09/miami/english',
      '/training/09/fort_lau','/training/09/fort_lau/english',
      '/training/09/nola','/training/09/nola/english',
      '/training/09/reno','/training/09/reno/english',
      '/training/09/sanfran','/training/09/sanfran/english',
      '/training/09/wpb','/training/09/wpb/english',
      '/training/10/nyc','/training/10/nyc/english',
      '/training/10/chicago','/training/10/chicago/english',
      '/training/10/chi','/training/10/chi/english',
      '/training/10/phl','/training/10/phl/english',
      '/training/10/dc', '/training/10/dc/english',
      '/training/10/la','/training/10/la/english',
      '/training/10/bos','/training/10/bos/english',
      '/training/10/miami','/training/10/miami/english',
      '/training/10/fort_lau','/training/10/fort_lau/english',
      '/training/10/nola','/training/10/nola/english',
      '/training/10/reno','/training/10/reno/english',
      '/training/10/sanfran','/training/10/sanfran/english',
      '/training/10/wpb','/training/10/wpb/english',
      '/training/11/la','/training/11/la/english',
      '/training/12/nyc','/training/12/nyc/english',
      '/training/12/chicago','/training/12/chicago/english',
      '/training/12/chi','/training/12/chi/english',
      '/training/12/phl','/training/12/phl/english',
      '/training/12/dc', '/training/12/dc/english',
      '/training/12/la','/training/12/la/english',
      '/training/12/bos','/training/12/bos/english',
      '/training/12/miami','/training/12/miami/english',
      '/training/12/fort_lau','/training/12/fort_lau/english',
      '/training/12/nola','/training/12/nola/english',
      '/training/12/reno','/training/12/reno/english',
      '/training/12/sanfran','/training/12/sanfran/english',
      '/training/12/wpb','/training/12/wpb/english',
      '/training/13/nyc','/training/13/nyc/english',
      '/training/14/nyc','/training/14/nyc/english',
      '/training/15/nyc','/training/15/nyc/english',
    ];

  return useRoutes(trainingVersions.map(path => ({path, element})));
}